import { HandleTokenExpiration } from "./HandleTokenExpiration";

const originalFetch = window.fetch;

// Override the global fetch function
export function overrideFetch(dispatch) {
    window.fetch = async function(...args) {
        // Check if token is expired before making any API call
        HandleTokenExpiration(dispatch);

        // Proceed with the original fetch request
        return originalFetch(...args);
    };
}
