import { useDispatch } from "react-redux";
import { LOGOUT } from "../../store/actions";

export function HandleTokenExpiration(dispatch) {
    const expirationTime = localStorage.getItem('token_expiration');
    const currentTime = new Date().getTime();

    if (expirationTime && currentTime > expirationTime) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('berry-account')
        localStorage.removeItem('id_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('user');
        localStorage.removeItem('token_expiration');
        localStorage.removeItem('persist:root');

        dispatch({ type: LOGOUT });
        
        if (window.location.pathname !== '/login') {
            window.location.href = '/login';
        }
    }
}
