import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@material-ui/core';

// project imports
import config from '../../../config';

const LogoLight = (props) => (
    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
        <SvgIcon {...props} viewBox="0 0 39 39" style={{ fontSize: 50 }}>
            <rect width="38.7159" height="38.7159" rx="19.358" fill="#F2EDF7" />
            <g clipPath="url(#clip0_414_8)">
                <g style={{ mixBlendMode: 'multiply' }}>
                    <path
                        d="M25.3649 25.0185L25.3213 29.2795C25.3153 29.8664 24.6458 30.28 23.9863 30.1052L15.1135 27.7535L15.5666 27.8566C15.5171 27.7831 15.4897 27.6986 15.4906 27.6084C15.4929 27.3879 15.665 27.2025 15.8988 27.1383C15.9439 27.126 15.9908 27.1186 16.0392 27.1177L24.5122 25.7971C24.9705 25.7259 25.3137 25.407 25.3644 25.0189L25.3649 25.0185Z"
                        fill="#D6C2ED"
                    />
                </g>
                <g style={{ mixBlendMode: 'multiply' }}>
                    <path
                        d="M25.5133 10.5025L25.4697 14.7615C25.427 14.374 25.0904 14.0472 24.6337 13.9661L16.189 12.4728C16.1405 12.4709 16.0938 12.4626 16.049 12.4494C15.816 12.3803 15.6483 12.1914 15.6505 11.971C15.6514 11.8803 15.6806 11.7968 15.7315 11.7244L15.3106 11.8107L24.196 9.64966C24.859 9.48838 25.5199 9.91555 25.5139 10.5025L25.5133 10.5025Z"
                        fill="#061123"
                    />
                </g>
                <path
                    style={{ mixBlendMode: 'multiply' }}
                    d="M25.4747 14.8522L25.3716 24.9283C25.3713 24.9586 25.3692 24.9885 25.3649 25.0188L25.3213 29.2794C25.3152 29.8663 24.6463 30.2799 23.9868 30.1051L15.114 27.7534L13.757 27.3955C13.3755 27.2946 13.0754 27.0157 13.0396 26.6772C13.0364 26.6473 13.035 26.6184 13.0353 26.5885L13.175 12.9417C13.1753 12.9128 13.1773 12.8829 13.1811 12.8546C13.2238 12.5169 13.5272 12.2441 13.9131 12.1496L15.3107 11.8101L15.7317 11.7238C15.6807 11.7963 15.6516 11.8803 15.6507 11.9704C15.6484 12.1909 15.8167 12.3798 16.0497 12.4488C16.094 12.462 16.1406 12.4708 16.1891 12.4728L24.6338 13.9665C25.0906 14.0471 25.4271 14.3744 25.4699 14.762C25.4736 14.7919 25.475 14.8223 25.4747 14.8522Z"
                    fill="url(#paint0_linear_414_8)"
                />
            </g>
            <defs>
                <linearGradient id="paint0_linear_414_8" x1="4.93931" y1="26.0357" x2="20.9854" y2="18.3529" gradientUnits="userSpaceOnUse">
                    <stop offset="0.02" stopColor="#6A9EFF" />
                    <stop offset="1" stopColor="#CAB5FF" />
                </linearGradient>
                <clipPath id="clip0_414_8">
                    <rect width="12.3186" height="20.531" fill="white" transform="translate(13.2102 9.5) rotate(0.586488)" />
                </clipPath>
            </defs>
        </SvgIcon>
    </ButtonBase>
);

export default LogoLight;
