import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';

// routing
import Routes from './routes';

// defaultTheme
import theme from './themes';

// project imports
import NavigationScroll from './layout/NavigationScroll';
import { HandleTokenExpiration } from './views/utilities/HandleTokenExpiration';
import { overrideFetch } from './views/utilities/GlobalFetch';

//-----------------------|| APP ||-----------------------//

const App = () => {
    const customization = useSelector((state) => state.customization);
    const dispatch = useDispatch();


    useEffect(() => {
        // Pass dispatch to the fetch override
        overrideFetch(dispatch);

        if (window.location.pathname !== '/login' && window.location.pathname !== '/register') {
            HandleTokenExpiration(dispatch);
        }
    }, [dispatch]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
