import axios from "axios";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import configData from "../../../config";

const ZendeskCallback = () => {
    const history = useHistory();

    useEffect(() => {
        const handleZendeskCallback = async () => {
            try {
                const params = new URLSearchParams(window.location.search);
                const code = params.get('code');  // Authorization code

                if (!code) {
                    console.error("Zendesk authorization code not found.");
                    return;
                }

                // Retrieve the local user ID from local storage (if stored)
                const user = JSON.parse(localStorage.getItem('user'));
                const userId = user ? user.id : null;

                if (!userId) {
                    console.error("Local user ID not found.");
                    return;
                }

                // Send the authorization code and user ID to the backend for token exchange
                const response = await axios.get(`${configData.API_SERVER}users/zendesk/callback`, {
                    params: { code, user_id: userId }
                });

                if (response.data.message === 'Zendesk connected successfully') {
                    history.push('/dashboard/integration');
                } else {
                    console.error('Zendesk connection failed:', response.data.error);
                }
            } catch (error) {
                console.error('Error handling Zendesk callback', error);
            }
        };

        handleZendeskCallback();
    }, [history]);

    return <div>Connecting to Zendesk...</div>;
};

export default ZendeskCallback;