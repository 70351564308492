import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import { makeStyles, useTheme } from '@material-ui/styles';
import { Box, Drawer, Typography, useMediaQuery, Button } from '@material-ui/core';
import { IconHelp, IconLogout } from '@tabler/icons';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import { drawerWidth } from './../../../store/constant';
import LogoLight from '../LogoSection/logoLight';

// style constant
const useStyles = makeStyles((theme) => ({
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0
        }
    },
    drawerPaper: {
        width: drawerWidth,
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
        borderRight: 'none'
    },
    ScrollHeight: {
        height: '100vh',
        paddingLeft: '16px',
        paddingRight: '16px',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 56px)'
        }
    },
    boxContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '10px 16px',
        marginTop: '10px',
    },
   
    logoText: {
        fontWeight: 'bold',
        color: "#000",
        fontSize: '1.5rem' ,
        marginLeft: "8px"
    },

    buttonContainer: {
        backgroundImage: 'url(/rocketImage.png)',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: '35%',
        padding: theme.spacing(2),
        color: '#000',
        textAlign: 'center'
    },

    scheduleButton: {
        backgroundColor: "#C7B4FF",
        color: "#000",
        marginTop: '10px',
        borderRadius: '8px',
        padding: '8px 16px',
        width: '60%'
    },
    helpSection: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        borderRadius: '10px',
        margin: '10px 16px'
    },
    helpText: {
        fontWeight: 500
    },
    helpIcon: {
        width: theme.spacing(4),
        height: theme.spacing(4)
    },
    logoutSection: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        borderRadius: '10px',
        margin: '10px 16px'
    },
    logoutText: {
        fontWeight: 500
    },
    logoutIcon: {
        backgroundColor: theme.palette.action.selected,
        width: theme.spacing(4),
        height: theme.spacing(4)
    }
}));

//-----------------------|| SIDEBAR DRAWER ||-----------------------//

const Sidebar = ({ drawerOpen, drawerToggle }) => {
    const classes = useStyles();
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const drawer = (
        <React.Fragment>
            <BrowserView>
                <PerfectScrollbar component="div" className={classes.ScrollHeight}>
                    {/* Logo and text section */}
                    <div className={classes.boxContainer}>
                        <LogoLight/>
                        <Typography variant="h6" className={classes.logoText}>
                            Noledgeloss
                        </Typography>
                    </div>
                    <MenuList />
                    <div className={classes.buttonContainer}>
                        <Typography variant="body2" color="black">
                            Book a one-on-one demo with us to get the best out of your software
                        </Typography>
                        <Button
                            variant="contained"
                            className={classes.scheduleButton}
                            onClick={() => window.open('https://tidycal.com/noledgeloss/30-minute-meeting-noledgeloss-mne5w0m', '_blank')}
                        >
                            Schedule
                        </Button>
                    </div>
                </PerfectScrollbar>
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 2 }}>
                    <MenuList />
                </Box>
            </MobileView>
        </React.Fragment>
    );

    return (
        <nav className={classes.drawer} aria-label="mailbox folders">
            <Drawer
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                classes={{
                    paper: classes.drawerPaper
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </nav>
    );
};

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func
};

export default Sidebar;
